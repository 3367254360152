body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/* quicksand-300 - latin */
@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 300;
	src: local(''),
	url('./fonts/quicksand-v22-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('./fonts/quicksand-v22-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-regular - latin */
@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 400;
	src: local(''),
	url('./fonts/quicksand-v22-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('./fonts/quicksand-v22-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-500 - latin */
@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 500;
	src: local(''),
	url('./fonts/quicksand-v22-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('./fonts/quicksand-v22-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-700 - latin */
@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 700;
	src: local(''),
	url('./fonts/quicksand-v22-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('./fonts/quicksand-v22-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* quicksand-600 - latin */
@font-face {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 600;
	src: local(''),
	url('./fonts/quicksand-v22-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
	url('./fonts/quicksand-v22-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
